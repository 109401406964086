import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
  "realm": "Google-dev",
  "url": "https://identity.mhealthcoach.net/",
  "sslRrequired": "external",
  "resource": "sentrihubdev",
  "clientId":"sentrihubdev",
});

export default keycloak;
